import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/starswap-front-home/starswap-front-home/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "/def640d1b282d4bf06d584682d254cbc/swap.gif",
        "alt": null
      }}></img></p>
    <p>{`Almost two years after our `}<a parentName="p" {...{
        "href": "https://unipig.exchange/"
      }}>{`Unipig`}</a>{` proof of concept, we are finally ready to announce the Alpha launch of Starswap v3 on the Optimistic Ethereum (OΞ) mainnet!`}</p>
    <p>{`Today`}{`’`}{`s launch marks the first step in the journey for DeFi applications to rival and surpass traditional web user experiences. Optimism`}{`’`}{`s roadmap promises further powerful improvements including additional scaling, smart wallet EOAs (no `}{`”`}{`approves`}{`”`}{`, pay gas in any token), and decentralization of the transaction sequencing operation.`}</p>
    <p>{`During this Alpha period, OΞ will support an initial throughput of 0.6 transactions per second. Because Starswap v3 is currently one of the few protocols deployed to OΞ, this should translate to transaction capacity roughly in line with L1. Unlike L1, transactions on OΞ confirm instantly — `}<strong parentName="p">{`no more pending or stuck swaps`}</strong>{`!`}</p>
    <p>{`Assuming Starswap v3 sees equal usage to L1, `}<strong parentName="p">{`OΞ should offer up to 10x transaction cost savings`}</strong>{`. Additional demand will drive gas costs higher — for more details, check out Optimism`}{`’`}{`s blog post. `}<strong parentName="p">{`Transaction speeds will ramp up over the coming weeks and months`}</strong>{` as the OΞ infrastructure is tested and optimized at scale, with the end goal of scaling to fully meet demand for low cost, high speed DEX trading.`}</p>
    <p>{`For more information on how Optimistic Ethereum works and its security model, check out this `}<a parentName="p" {...{
        "href": "https://research.paradigm.xyz/rollups"
      }}>{`blog post`}</a>{`.`}</p>
    <h1 {...{
      "id": "launch-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#launch-details",
        "aria-label": "launch details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Launch Details`}</h1>
    <p>{`Users can start migrating assets over to the Optimism Ethereum network through the `}<a parentName="p" {...{
        "href": "https://gateway.optimism.io/"
      }}>{`Optimism Gateway`}</a>{`. A detailed user guide is available `}<a parentName="p" {...{
        "href": "https://help.starswap.xyz/en/collections/3033942-layer-2"
      }}>{`here`}</a>{`. Optimism is already compatible with popular wallets (Metamask, WalletConnect), Etherscan, and The Graph. Analytics can be tracked at info.starswap.xyz/#/optimism/.`}</p>
    <p><strong parentName="p">{`Please treat this as an early alpha product`}</strong>{`. Optimistic Ethereum is a complex Layer 2 scaling solution still in need of rigorous battletesting. At launch, the Optimism team will have upgrade rights over the bridge contract, allowing them to address any bugs that should arise.`}</p>
    <p>{`There are a few more Alpha launch features to be aware of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Downtime:`}</strong>{` There will be downtime (both planned and likely unplanned) during the early days of OΞ, during which LPs will not earn trading fees to compensate for price exposure. We will communicate scheduled downtime ahead of time through social media channels.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Withdrawal Times:`}</strong>{` Deposits from Ethereum L1 to Optimistic Ethereum are instant. However, until fast withdrawal services are made available, withdrawals from OΞ to L1 will take 7 days.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Select Assets:`}</strong>{` Starswap v3 can support any ERC20 token on OΞ, but will offer a limited number of assets at launch (ETH, USDT, WBTC, DAI, SNX). More tokens will be bridged to Optimistic Ethereum over the coming days and weeks.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`TWAP Oracles:`}</strong>{` The Optimism sequencer introduces an additional trust assumption for developers using v3 price feeds.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Composability:`}</strong>{` OΞ supports full composability. However, at launch, the network does not permit arbitrary smart contract deployment. Reach out to the Optimism team on Discord if you would like to deploy your contracts on OΞ.`}</p>
      </li>
    </ul>
    <p>{`Scaling Ethereum will be an iterative process, but this is a major step forward. Optimistic Rollups and ETH 2.0 are complementary scaling solutions and together will propel DeFi to mainstream adoption.`}</p>
    <hr></hr>
    <p>{`To get involved and stay up to date:`}</p>
    <ul>
      <li parentName="ul">{`Join the Starswap community `}<a parentName="li" {...{
          "href": "https://discord.gg/FCfyBSbCU5"
        }}>{`discord`}</a></li>
      <li parentName="ul">{`Follow Starswap on `}<a parentName="li" {...{
          "href": "/Starswap"
        }}>{`Twitter`}</a></li>
      <li parentName="ul">{`Subscribe to the Starswap `}<a parentName="li" {...{
          "href": "/blog"
        }}>{`blog`}</a></li>
      <li parentName="ul">{`Register as a delegate `}<a parentName="li" {...{
          "href": "http://sybil.org/"
        }}>{`Sybil`}</a></li>
      <li parentName="ul">{`Participate in `}<a parentName="li" {...{
          "href": "http://gov.starswap.xyz/"
        }}>{`Starswap governance`}</a></li>
    </ul>
    <p>{`Starswap Team
🦄`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      